import {ActionCreatorsTypes} from "../../enums/ActionCreatorsTypes";

export interface INewsState {
    newsInit: IMewsInitResponse;
    loaded: boolean;

}


export interface ISingleNews {
    id: number,
    title: string,
    text: string,
    subtitle: string,
    videoUrl: string,
    tag: number,
    newsDate: string,
    image: {
        id: number,
        newsId: number,
        image: string
    }

}

export interface INewsFetchData {
    singleNews: any;
    lastThreeNews: any[];
}

interface ITag {
    id: number;
    name: string;
}

export interface INewsItem {
    id: number;
    title: string;
    tagName: string;
    image: string;
}

export interface INewsByTag {
    tagId: number;
    news: INewsItem[];
}

export interface IMewsInitResponse {
    nextLiveEvent: string;
    tags: ITag[];
    newsCarousel: INewsItem[];
    newsByTag: INewsByTag;
}

export interface IGetNews {
    type: ActionCreatorsTypes.GET_NEWS;
}

export interface ISetNews {
    type: ActionCreatorsTypes.SET_NEWS;
    payload: IMewsInitResponse;
}
export interface IGetNewsByTag {
    type: ActionCreatorsTypes.GET_NEWS_BY_TAG;
    payload: number | null;
}

export interface ISetNewsByTag {
    type: ActionCreatorsTypes.SET_NEWS_BY_TAG;
    payload: INewsByTag;
}

export interface INewsLoader {
    type: ActionCreatorsTypes.NEWS_LOADER;
    payload: boolean;
}
export interface IGetNewsCarousel {
    type: ActionCreatorsTypes.GET_NEWS_CAROUSEL;

}
export interface ISetNewsCarousel {
    type: ActionCreatorsTypes.SET_NEWS_CAROUSEL;
    payload: INewsItem[]

}
export type NewsReducerType = ISetNews | INewsLoader | ISetNewsByTag | IGetNewsByTag| ISetNewsCarousel

export const defaultState: INewsState = {
    newsInit: {
        newsByTag: {
            tagId: 0,
            news: [] as INewsItem[]
        },
        nextLiveEvent: "",
        newsCarousel: [],
        tags: []
    },
    loaded: false
}
