import {ActionCreatorsTypes} from "../../enums/ActionCreatorsTypes";
import {
    IGetNews,
    IGetNewsByTag, IGetNewsCarousel,
    IMewsInitResponse,
    INewsByTag, INewsItem,
    INewsLoader,
    ISetNews, ISetNewsByTag, ISetNewsCarousel
} from "../../saga/mss-news/models";
import {
    IGetResultsPage,
    ISetResultsPage,
    ISetSelectedGroup,
    ISetSelectedSeries,
    ISetSelectedWeek,
    ISetFilteredFixtures,
    IResultsPageData,
    IResultsInitResponse, IGetResultByFilter,
} from "../../saga/fixtures/models";

export const ActionsCreator = {
    getNews: (): IGetNews => (
        {type: ActionCreatorsTypes.GET_NEWS}
    ),
    setNews: (payload: IMewsInitResponse): ISetNews => (
        {type: ActionCreatorsTypes.SET_NEWS, payload}),

    getNewsByTag: (payload: number | null): IGetNewsByTag => (
        {type: ActionCreatorsTypes.GET_NEWS_BY_TAG, payload}),

    getNewsCarousel: (): IGetNewsCarousel => (
        {type: ActionCreatorsTypes.GET_NEWS_CAROUSEL}),

    setNewsCarousel: (payload: INewsItem[]): ISetNewsCarousel => (
        {type: ActionCreatorsTypes.SET_NEWS_CAROUSEL, payload}),

    setNewsByTag: (payload: INewsByTag): ISetNewsByTag => (
        {type: ActionCreatorsTypes.SET_NEWS_BY_TAG, payload}),

    loaderNews: (payload: boolean): INewsLoader => (
        {type: ActionCreatorsTypes.NEWS_LOADER, payload}),

    getResults: (): IGetResultsPage => (
        {type: ActionCreatorsTypes.GET_RESULTS_PAGE}),

    getResultsByFilter: (payload: string): IGetResultByFilter => (
        {type: ActionCreatorsTypes.GET_RESULTS_BY_FILTER, payload}),

    setResults: (payload: IResultsInitResponse): ISetResultsPage => (
        {type: ActionCreatorsTypes.SET_RESULTS_PAGE, payload}),

    setFilteredFixtures: (payload: IResultsPageData): ISetFilteredFixtures => (
        {type: ActionCreatorsTypes.SET_FILTERED_FIXTURES, payload}),

    setSelectedSeries: (payload: number): ISetSelectedSeries => (
        {type: ActionCreatorsTypes.SET_SELECTED_SERIES, payload}),

    setSelectedWeek: (payload: number): ISetSelectedWeek => (
        {type: ActionCreatorsTypes.SET_SELECTED_WEEK, payload}),

    setSelectedGroup: (payload: number): ISetSelectedGroup => (
        {type: ActionCreatorsTypes.SET_SELECTED_GROUP, payload}),
}
