import React, {useEffect, useState} from 'react';
import '../../assets/css/global.scss';
import {useLocation, useParams} from "react-router-dom";
import {SingleNewsItem} from "./SingleNewsItem";
import {client} from "../../services/api.service";
import Loader from "../../components/Loader/Loader";
import {INewsFetchData, ISingleNews} from "../../saga/mss-news/models";

const NewsSingleOne: React.FC = () => {
    const [newsData, setNewsData] = useState<ISingleNews | null>(null);

    const {id} = useParams<{id:string}>();
    const fetchNewsData = async (id: number) => {
        try {
            const resp = await client.get(`/api/mss-web/news/${id}`);
            setNewsData(resp.data)
        } catch (e: unknown) {
            if (e instanceof Error) {
                console.log('fetchNewsData:', e.message)
            }
        }
    }


    useEffect(() => {
        if (id){
            fetchNewsData(+id)
        }
    }, [id])

    const render = () => {
        {
            return newsData
                ?
                <SingleNewsItem news={newsData}/>
                :
                <Loader/>
        }
    }

    return (
        <div>
            {render()}
        </div>
    )
}
export default NewsSingleOne;
