import {defaultState, NewsReducerType} from "./models";
import {ActionCreatorsTypes} from "../../enums/ActionCreatorsTypes";

export const newsReducer = (state = defaultState, action: NewsReducerType) => {
    switch (action.type) {
        case ActionCreatorsTypes.SET_NEWS:
            return {...state, newsInit: action.payload}

        case ActionCreatorsTypes.SET_NEWS_BY_TAG:
            return {...state, newsInit: {...state.newsInit, newsByTag: action.payload}}

        case ActionCreatorsTypes.NEWS_LOADER:
            return {...state, loaded: action.payload}

        case ActionCreatorsTypes.SET_NEWS_CAROUSEL:
            return {...state, newsInit: {...state.newsInit, newsCarousel: action.payload}}
        default:
            return state;
    }
}

