import {client} from "../../services/api.service";
import {IMewsInitResponse, INewsByTag} from "./models";


export const getMssNewsRequest = async (): Promise<IMewsInitResponse> => {
    const news = await client.get('/api/mss-web/news-init');
    return news.data;
}

export const getNewsCarouselRequest = async (): Promise<IMewsInitResponse> => {
    const news = await client.get('/api/mss-web/news-carousel');
    return news.data;
}

export const getNewsByTagRequest = async (tagId: number | null): Promise<INewsByTag> => {
    if (tagId !== null) {
        const news = await client.get(`/api/mss-web/news-by-tag/?tag=${tagId}`);
        return news.data;
    } else {
        const news = await client.get(`/api/mss-web/news-by-tag`);
        return news.data;
    }

}


