import {getMssNewsRequest, getNewsByTagRequest, getNewsCarouselRequest} from "./request";
import {call, put,takeEvery} from 'redux-saga/effects';
import {ActionsCreator} from "../../redux/actions/actionCreator";
import {IGetNewsByTag, IMewsInitResponse, INewsByTag, INewsItem} from "./models";

import {ActionCreatorsTypes} from "../../enums/ActionCreatorsTypes";

function* tryGetMssNews() {
    try {
        yield put(ActionsCreator.loaderNews(true));
        const news: IMewsInitResponse = yield call(getMssNewsRequest);
        yield put(ActionsCreator.setNews(news));
    } catch (e: unknown) {
        if (e instanceof Error) {
            yield put(ActionsCreator.loaderNews(false));
            console.log('tryGetMssNews:', e.message);
        }
    }
}

function* tryGetNewsByTag({payload}: IGetNewsByTag) {
    try {
        const news: INewsByTag = yield call(getNewsByTagRequest, payload);
        yield put(ActionsCreator.setNewsByTag(news));
    } catch (e: unknown) {
        if (e instanceof Error) {
            console.log('tryGetNewsByTag:', e.message);
        }
    }
}
function* tryGetNewsCarousel() {
    try {
        const news: INewsItem[] = yield call(getNewsCarouselRequest);
        yield put(ActionsCreator.setNewsCarousel(news));
    } catch (e: unknown) {
        if (e instanceof Error) {
            console.log('tryGetNewsCarousel:', e.message);
        }
    }
}



export default function* mssNewsSagas() {
    yield takeEvery(ActionCreatorsTypes.GET_NEWS, tryGetMssNews);
    yield takeEvery(ActionCreatorsTypes.GET_NEWS_BY_TAG, tryGetNewsByTag);
    yield takeEvery(ActionCreatorsTypes.GET_NEWS_CAROUSEL, tryGetNewsCarousel);
}
